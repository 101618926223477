import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import Layout from "../components/Layout/layout"
import BlogItems from "../components/Blog/BlogItems/blogItems"

const blog = (props) => {
  const { locale } = props.pageContext
  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["blogPageTitle"]}
           lang={props.pageContext.locale}
           description={translationHelper[locale]["blogPageDescription"]}
      />
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator pixels={40}/>
            <SectionTitleSeperator h1Override={true} hideTop={true} title={translationHelper[locale]["Blog"]}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <BlogItems items={props.data.blogs.edges}/>
            <EmptySpaceSeperator pixels={50}/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default blog

export const blogPageQuery = graphql`
    query($locale: String!) {
        blogs: allWordpressPost(filter: {polylang_current_lang: {eq: $locale}}) {
            edges {
                node {
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                        alt_text
                    }
                    excerpt
                    categories {
                        name
                        slug
                        path
                    }
                    id
                    title
                    slug
                    polylang_current_lang
                    modified(formatString:"MMMM YYYY")
                    tags {
                        name
                    }
                }
            }
        }

    }

`